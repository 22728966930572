<template>
    <div class="min-h-screen bg-gray-900 background-image">
        <div class="flex ml-10 mr-10 py-10 mx-auto w-auto h-12">
            <img class="flex-initial h-8 w-auto" src="@/assets/images/logo/logo-white.svg" alt="Scorelit">
            <div class="flex-auto "></div>
            <div v-if="isLoggedIn()" @click="logout" class="flex-initial min-width-40 cursor-pointer">
                <svg class="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
            </div>
        </div>
        <div class=" flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <slot />
        </div>
    </div>
</template>

<script>
import { isLoggedIn, logout } from "@/helper/api/AuthenticationHelper";

export default {
    name: "Guest",
    methods: {
        logout(){
            logout()
                .then(()=>{
                    this.$router.push("/login");
                })
                .catch((data)=>{
                    console.error('Logout unsuccessful: ', data)
                });
        },
        isLoggedIn,
    },
}
</script>

<style scoped>
    .background-image {
        /* https://www.w3schools.com/html/html_images_background.asp */
        background: #000000;
        background-image: url('~@/assets/images/background/header.jpg'); /* fallback */
        background-image: linear-gradient(#000000, #0000), url('~@/assets/images/background/header.jpg');
        background-repeat: no-repeat;
        background-position: center; 
        background-attachment: fixed;
        background-size: cover;
    }
    .min-width-40 {
        min-width: 40px;
    }
</style>